<template>
    <div>
        <v-skeleton-loader v-bind="attrs" type="card" v-if="loader === true">
        </v-skeleton-loader>
        <div v-else>
            <!-- <table class="table" v-if="response === true">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Exchange name</th>
                        <th scope="col">Exchange rate</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, elt) in exchangerate.data" :keys="i">
                        <th scope="row">{{elt}}</th>
                        <td>{{item}}</td>
                    </tr>
                </tbody>
            </table> -->
            <v-data-table v-if="response === true" :headers="headers" :loading="!response"
                loading-text="Loading... Please wait" :items="exchangerate.data" :search="search" sort-by="exchange"
                class="elevation-1 row-pointer" style="cursor: pointer" @click:row="(item) => viewExchagedetail(item)">
                <template v-slot:item.exchange="{ item }">
                    <div class="">{{ item.name }}</div>
                </template>
                <template v-slot:item.rate="{ item }">
                    <div class="">{{ item.rate }}</div>
                </template>
                <template v-slot:item.last="{ item }">
                    <div class="">{{ exchangerate.lastup }}</div>
                </template>

                <template v-slot:item.next="{ item }">
                    <div class="">{{ exchangerate.nextup }}</div>
                </template>
            </v-data-table>
            <div v-else>
                <h3 class="error--text">Loading exchange..1</h3>
                <v-skeleton-loader v-bind="attrs" type="card">
                </v-skeleton-loader>
            </div>
        </div>
    </div>
</template>

<script>
import { Drequest } from '@/plugins/Drequest';

export default {
    data() {
        return {
            loader: true,
            response: false,
            attrs: {},
            search: '',
            exchangerate: {
                data: [],
                lastup: '',
                nextup: '',
                code: ''
            },

            headers: [
                {
                    text: 'Exchange',
                    align: 'start',
                    sortable: true,
                    value: 'exchange'
                },
                {
                    text: 'Rate',
                    align: 'start',
                    sortable: true,
                    value: 'rate'
                },
                {
                    text: 'Last update',
                    align: 'start',
                    sortable: true,
                    value: 'last'
                },
                {
                    text: 'Next update',
                    align: 'start',
                    sortable: true,
                    value: 'next'
                },
            ]
        }
    },

    methods: {
        viewExchagedetail(value) { }
    },
    beforeMount() {
        this.loader = true
        var link_ref = "https://v6.exchangerate-api.com/v6/6feae95719b96979de7c0077/latest/USD"
        if (this.loader === false) {
            Drequest.ApiExternal(`${link_ref}`)
                .get((res) => {
                    if (res.result === 'success') {
                        this.loader = false
                        this.exchangerate.data = Object.entries(res.conversion_rates).map((arr) => ({
                            name: arr[0],
                            rate: arr[1],
                        }));
                        this.exchangerate.lastup = res.time_last_update_utc
                        this.exchangerate.nextup = res.time_next_update_utc
                        this.exchangerate.code = res.base_code
                        this.response = true
                    }
                    else {
                        this.loader = false
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.loader = false
                })
        }
    }
}
</script>