<template>
    <v-skeleton-loader v-bind="attrs" type="card" v-if="loader === true">
    </v-skeleton-loader>
    <v-row class="commodities" v-else>
        <v-col cols="4" sm="3" md="2" class="d-flex flex-row justify-center align-center"
            v-for="(item, i) in commodities_test.rates" :key="i">
            <div class="commodities-box">
                <div class="commodities-title">
                    <span class="title">{{ item.title }}</span>
                    <!-- <span class="title">{{commoditie}}</span> -->
                    <!-- <span class="text-caption ml-1">Commodity</span> -->
                </div>
                <div class="commodities-price">{{ Math.round(item.value * 1000) / 1000 }} <span class="ml-1">
                        {{ commodities_test.base }}</span></div>
                <div class="commodities-time">
                    Last update: <span class="time">{{ commodities_test.date }} </span>
                </div>
            </div>
        </v-col>
    </v-row>

</template>

<script>

import { Drequest } from '@/plugins/Drequest';

export default {
    // props: {
    //     commoditie: {
    //         type: Object,
    //         default: () => { }
    //     }
    // },
    data() {
        return {
            loader: true,
            commodities_test: {
                base: "USD",
                date: "2023-03-29",
                rates: [
                    { title: "ARABICA COFFEE", value: 1.688 },
                    { title: "ROBUSTA COFFEE", value: 2139 },
                    { title: "BRENT OIL", value: 79.500000000002 },
                    { title: "CORN", value: 6.5350000000001 },
                    { title: "COTTON", value: 0.81999999999997 },
                    { title: "ETHANOL", value: 2.161 },
                    { title: "LUMBER", value: 384.3 },
                    { title: "MILK", value: 18.05 },
                    { title: "NATURAL GAS", value: 1.976 },
                    { title: "RICE", value: 17.78 },
                    { title: "RUBBER", value: 1.64 },
                    { title: "SOYBEAN", value: 14.66 },
                    { title: "WHEAT", value: 292.626 },
                    { title: "WTI OIL", value: 74.110000000001 },
                    { title: "OAT", value: 376.4 },
                    { title: "LIVE CATTLE", value: 1.6515 },
                    { title: "GOLD", value: 1966.0171453061 },
                    { title: "SILVER", value: 23.237603947502 },
                    { title: "PLATINUM", value: 961.00000000004 },
                    { title: "PALLADIUM", value: 1375 },
                    { title: "RHODIUM", value: 8349.9999999997 },
                    { title: "RUTHENIUM", value: 750.00000000002 },
                    { title: "CORN DEC 23", value: 575.24999999998 },
                    { title: "WHEAT FRONT MONTH", value: 721.49999999999 },
                    { title: "WHEAT DEC 23", value: 760.25000000002 },
                ],
                unit: "per barrel for Oil, per ounce for Metals.Per 10 metric tons for Crude Palm Oil, Per MMBtu for Natural gas, Per Gallon for Ethanol.Per metric ton, per lb or per bushel for Agriculture"
            },
            commodities: {
                base: "",
                date: "",
                rates: [ ],
                unit: ""
            },
            attrs: {}
        }
    },
    beforeMount() {
        this.loader = true
        // Drequest.ApiExternal('https://commodities-api.com/6feae95719b96979de7c0077/latest')
        //     .get((res) => {
        //         if (res.success === 'true') {
        //             this.commodities.rates = Object.entries(res.rates).map((arr) => ({
        //                 name: arr[0],
        //                 value: arr[1],
        //             }));
        //             this.base = res.base
        //             this.date = res.date
        //             this.unit = res.unit
        //         }
        //         else {
        //             this.loader = false
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //         this.loader = false
        //     })
    },
    mounted() {
        this.loader = false
    },
}
</script>

<style lang="scss" scoped>
.commodities {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    text-align: left;
}

.commodities-box {
    border: solid 0.1em var(--v-primary-base);
    border-radius: 1em;
    padding: 0.4em 0.6em;
    //vertical-align: center;
    /*padding-top: 0;
    padding-left: 0.8em;
    padding-right: auto;
    padding-bottom: 0px;*/
}

.commodities-title {
    align-items: flex-start;
    overflow: hidden !important;
    text-overflow: clip !important;
    height: 25px !important;

    .title {
        //display: flex;
        //flex-direction: row;
        //flex-wrap: nowrap;
        font-weight: bold;
        font-size: 14px !important;
        line-height: 14px;
        color: var(--v-primary-base) !important;
    }

    .type {
        margin-left: 0.4em;
        color: var(--v-secondary-base) !important;
    }
}

.commodities-price {
    font-weight: bold;
    font-size: 12px !important;
    line-height: 12px;
    color: var(--v-secondary-base) !important;
}

.commodities-time {
    padding-bottom: 0px;
    font-size: 11px !important;

    .time {
        //font-weight: bold;
        font-size: 11px !important;
        line-height: 10px;
        //color: var(--v-secondary-base) !important;
    }
}
</style>