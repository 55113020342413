<template>
    <div class="socialize-component">
        <div class="socialize-text">
            <h1><span class="primary--text">Connect</span> and
            </h1>
            <div class="hr-line-style">
                <h1 class="secondary--text">
                    network!
                </h1>
                <v-img :src="require('@/assets/images/pages/cuve-line.svg')" alt="Connect and socialize"
                    class="hr-line ml-2"></v-img>
            </div>

            <p class="flex-wrap">
                <span>Join us to drive subcontracting and investment in Africa</span>
            </p>

        </div>
        <v-img :src="require('@/assets/images/pages/connect-box.svg')" alt="Connect and socialize"
            class="mx-auto img-socialize"></v-img>
    </div>

</template>

<script>

import { ref } from '@vue/composition-api'

export default {
    props: {
        title: {
            type: String
        }
    },
    setup() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
.socialize-component {
    position: relative;
    display: block;
    margin-right: 3rem;
    color: #333333;
    width: 80%;
    justify-content: flex-end;
}

.socialize-component .img-socialize {
    height: 60%;
}

.socialize-text {
    position: absolute;
    top: 12%;
    left: 20%;
    z-index: 50;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 80%;

}

.socialize-text h1 {
    font-size: 30pt;
    width: fit-content;
}

.hr-line-style {
    width: min-content;
    margin-top: -0.8em;
}

.hr-line-style .hr-line {
    width: 55%;
    margin-top: -0.18em;
    margin-left: 0.2em;
}

.socialize-component p {
    font-size: 13pt;
    width: 90%;
}

.over-primary {
    color: var(--v-primary-base);
}
</style>
