<template>
  <div>
    <!-- <div class="home-started-block"> -->
    <div class="home-started-block bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
      <div class="container mx-auto px-6 flex relative py-16">
        <div class="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20">
          <span class="w-20 h-2 primaryAccent dark:bg-white mb-12">
          </span>
          <h1
            class="font-bebas-neue text-4xl sm:text-8xl font-white flex flex-col leading-none dark:text-gray-700 text-white">
            Welcome to
            <span class="text-6xl sm:text-8xl primary--text">
              Dyulaba!
            </span>
          </h1>
          <h3 class="text-xl sm:text-base text-white dark:text-gray-700">
            The ideal place to connect and network with African businesses, organizations and professionals
          </h3>
          <h4 class="text-2xl sm:text-base primary--text dark:text-gray-700">
            Let’s drive contracting and investments in Africa together
          </h4>
          <br/>
          <!-- <div class="flex mt-8">
            <a href="#"
              class="uppercase py-2 px-4 rounded-lg bg-pink-500 border-2 border-transparent text-white text-md mr-4 hover:bg-pink-400">
              Get started
            </a>
            <a href="#"
              class="uppercase py-2 px-4 rounded-lg bg-transparent border-2 border-pink-500 text-pink-500 dark:text-white hover:bg-pink-500 hover:text-white text-md">
              Read more
            </a>
          </div> -->
          <!-- <div class="home-started-btn" v-if="!(this.$store.getters.isLoggedIn)"> -->
          <div class="home-started-btn">
            <v-btn class="home-started-log-btn mr-2" :to="{ name: 'register' }">
              Get started</v-btn>

            <v-btn class="home-started-vid-btn" @click="toLoggin">Watch demo
            </v-btn>
          </div>
        </div>
        <!-- <div class="hidden sm:block sm:w-1/3 lg:w-3/5 relative">
          <img src="https://www.tailwind-kit.com/images/object/10.png" class="max-w-xs md:max-w-sm m-auto" />
        </div> -->
      </div>
      <div class="home-started-bg">
        <!-- <div class="area"> -->
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <!-- </div> -->
      </div>
      <!-- </div> -->
    </div>
    <!-- Welcome main section -->
    <!-- <v-row class="main-sec">
      <div class="btn-to-bottom" @click="toBottom"></div>
      <div class="main-sec-text">
        <h1 class="main-sec-title">Welcome to Duylaba!</h1>
        <h5 class="main-sec-subtitle">The ideal place to connect and network with African
          businesses, organizations and professionals</h5>
        <h5 class="main-sec-subtitle main-sec-subtitle-slog">Let’s drive subcontracting and investments in Africa
          together</h5>
        <div class="d-flex flex-row justify-center mx-auto pt-4" v-if="!(this.$store.getters.isLoggedIn)">
          <v-btn outlined color="primaryAccent" class="home-signup-btn" :to="{ name: 'register' }">
            Sign Up</v-btn>

          <v-btn class="home-signin-btn" v-if="!(this.$store.getters.isLoggedIn)" @click="toLoggin">Log in here
          </v-btn>
        </div>
      </div>
    </v-row> -->

    <v-row class="main-subsec">
      <v-col cols="11" md="4" class="main-subsec-item mx-auto hidden-sm-and-down">
        <div class="main-sec-text">
          <!-- <h5 class="main-sec-subtitle">Connect, Network, Do business and Invest in Africa</h5> -->
          <!-- <p class="main-sec-subtitle-sub">Connect, Network, Do business and Invest in Africa
          </p> -->
        </div>
      </v-col>
      <v-col cols="11" md="4" class="main-subsec-item mx-auto">
        <div class="main-sec-text">
          <h5 class="main-sec-subtitle">Connect, Network, Do business and Invest! <br> <span class="font-weight-bold primary--text"> Together, let's industialise Africa !</span></h5>
          <!-- <p class="main-sec-subtitle-sub">Connect, Network, Do business and Invest in Africa
          </p> -->
        </div>
      </v-col>
      <v-col cols="11" md="4" class="main-subsec-item mx-auto hidden-sm-and-down">
        <div class="main-sec-text">
          <!-- <h5 class="main-sec-subtitle">Connect, Network, Do business and Invest in Africa</h5> -->
          <!-- <p class="main-sec-subtitle-sub">Connect, Network, Do business and Invest in Africa
          </p> -->
        </div>
      </v-col>
    </v-row>
    <!-- LogIn section -->
    <v-row class="d-flex flex-row justify-center align-center pa-10" ref="logginref">
      <!-- <v-row class="d-flex justify-center align-center" ref="logginref" v-if="!(this.$store.getters.isLoggedIn)"> -->
      <v-col md="4" cols="10" class="hidden-sm-and-down">
        <socialize-block-component></socialize-block-component>
      </v-col>
      <v-col md="4" cols="10" class="pa-4" v-if="!(this.$store.getters.isLoggedIn)">
        <v-card class="px-4 py-10 round-0" flat>
          <!-- <v-row class="h-full"> -->
          <!-- <v-col sm="12" md="6" class="flex-column align-center justify-center py-0 relative-loader-img">
              <v-img :src="require('@/assets/images/pages/person-client.png')" alt="Connect and socialize"
                class="h-full rd-left" @load="loadImage">
              </v-img>
              <v-col cols="6" class="my-auto mx-auto text-center float-rel-loader-img" v-if="!loader.image">
                <p>Loading image</p>
                <v-progress-circular :size="70" :width="7" color="subsecondary" indeterminate></v-progress-circular>
              </v-col>
            </v-col> -->
          <!-- <v-col sm="12" md="6" lg="6" class="py-4 pr-6"> -->
          <v-card-title class="d-flex justify-center ">
            <h1 class="font-weight-bold">
              Log In
            </h1>
          </v-card-title>
          <v-card-text class="d-flex align-center justify-center flex-wrap">
            <span class="me-2 text-center">
              Don’t have an account yet?
            </span>
            <router-link :to="{ name: 'register' }">
              Sign Up
            </router-link>
          </v-card-text>
          <login-form></login-form>
          <!-- </v-col> -->
          <!-- </v-row> -->
        </v-card>
      </v-col>
      <v-col cols="0" md="3" v-if="(this.$store.getters.isLoggedIn)">
        <v-card class="my-8 w-full" tile height="auto">
          <v-img :src="require('@/assets/images/pages/person-client.png')" alt="Connect and socialize" class=""
            height="60vh" top @load="loadImage" cover>
          </v-img>
        </v-card>
      </v-col>
    </v-row>

    <!-- Network with companies section -->
    <v-row class="sec-2hpart">
      <v-col cols="12" class="sec-2part-start secondary">
        <h1 class="hr-style text-center white--text pl-4">Collaborate with businesses and <br />other Organisations
          <hr class="white" />
        </h1>
      </v-col>
      <v-col cols="12" class="sec-2part-float">
        <slide-component :items="companylist" :component="'company'"></slide-component>
      </v-col>
      <v-col cols="12" class="sec-2part-end subsecondary">
        <v-btn class="px-4 py-4 my-4 secondary" color="secondaryAccent" :to="{ name: 'company' }" outlined rounded>
          View more</v-btn>
      </v-col>
    </v-row>

    <!--  Calls for proposals -->
    <v-row class="d-flex secondaryAccent flex-column py-4" align="center" justify="center">
      <v-col cols="10" align="center" justify="center">
        <h1 class="hr-style secondary--text">Calls for proposals
          <hr class="secondary" />
        </h1>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.lgAndUp ? '10' : ($vuetify.breakpoint.mdOnly ? '10' : '11')">
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col cols="6" md="4" v-for="(item, index) in proposallist" :key="index">
            <proposal-card :proposal="item">
            </proposal-card>
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.smAndDown">
          <v-col cols="12" md="4" v-for="i in 2" :key="i">
            <proposal-card :proposal="proposallist[i]">
            </proposal-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="10" class="mb-6" justify="center" align="center">
        <v-btn class="px-4 py-2 secondary" color="secondaryAccent" :to="{ name: 'proposal.list' }" outlined rounded>
          View more
        </v-btn>
      </v-col>
    </v-row>

    <!-- Connect with professionals -->
    <v-row class="sec-2hpart">
      <v-col cols="12" class="sec-2part-start primary">
        <h1 class="hr-style white--text pl-4">Network with professionals
          <hr class="white" />
        </h1>
      </v-col>
      <v-col cols="12" class="sec-2part-float">
        <slide-component :items="userslist" :speed="4000" :component="'user'" v-if="loader.professional">
        </slide-component>
        <v-card class="d-flex flex-row" v-else>
          <v-progress-circular class="mx-auto my-8" :size="70" :width="7" color="secondary" indeterminate>
          </v-progress-circular>
        </v-card>
      </v-col>
      <v-col cols="12" class="sec-2part-end subprimary pb-10">
        <v-btn class="px-4 py-2 primary" color="secondaryAccent" :to="{ name: 'professional' }" outlined rounded>
          View more </v-btn>
      </v-col>
    </v-row>

    <!--  Vacancies -->
    <v-row class="d-flex secondaryAccent flex-column py-4" align="center" justify="center">
      <v-col cols="10" align="center" justify="center">
        <h1 class="hr-style secondary--text">Vacancies
          <hr class="secondary" />
        </h1>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.lgAndUp ? '10' : ($vuetify.breakpoint.mdOnly ? '11' : '11')">
        <div v-if="$vuetify.breakpoint.mdAndUp">
          <v-row v-if="loader.vacancies == false">
            <v-col cols="6" sm="1" md="4" v-for="(item, index) in vacancieslist" :key="index">
              <job-card :value="item">
              </job-card>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="6" md="4" v-for="index in 3" :key="index">
              <v-card outlined>
                <v-skeleton-loader class="mx-auto border" max-width="300" type="article, actions">
                </v-skeleton-loader>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <v-row v-if="$vuetify.breakpoint.smAndDown">
          <job-card :value="vacancieslist[1]" v-if="loader.vacancies===false">
          </job-card>
          <v-card outlined v-else>
            <v-skeleton-loader class="mx-auto border" max-width="300" type="article, actions">
            </v-skeleton-loader>
          </v-card>
        </v-row>
      </v-col>
      <v-col cols="10" class="mb-6" justify="center" align="center">
        <v-btn class="px-4 py-2 secondary" color="secondaryAccent" :to="{ name: 'vacancies.list' }" outlined rounded>
          View more
        </v-btn>
      </v-col>
    </v-row>

    <!-- Some releases app -->
    <!-- <some-releases></some-releases> -->

    <!-- API specifics services -->
    <v-row class="mb-2">
      <!-- <v-col cols="12" md="4" lg="4" class="pa-0"> -->
      <v-col cols="10" class="pa-4 mx-auto my-4">
        <!-- <v-card tile flat height="100%" class="d-flex flex-column justify-center align-center px-10 py-4"> -->
        <!-- <v-card-text class="text-center"> -->
        <h2 class="exchange-text primary--text my-8">Commodities Prices</h2>
        <!-- </v-card-text> -->
        <!-- <v-card-text class="text-center"> -->
        <commodities></commodities>
        <!-- </v-card-text> -->
        <!-- </v-card> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" class="pa-4 mx-auto my-4">
        <!-- <v-col cols="12" class="pa-0"> -->
        <!-- <v-card tile flat height="100%" color="primary" class="d-flex flex-column justify-center align-center pa-10"> -->
        <!-- <v-card-text class="text-center"> -->
        <h2 class="secondary--text my-8">Exchange Rates</h2>
        <!-- </v-card-text> -->
        <!-- <exchange class="mx-auto"></exchange> -->
        <v-row>
          <v-col cols="6">
            <grin></grin>
          </v-col>
          <v-col cols="6">
            <grin></grin>
          </v-col>
        </v-row>
        <!-- </v-card> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline, mdiHelpCircleOutline, mdiArrowDownCircleOutline } from '@mdi/js'
// import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

import SocializeBlockComponent from './components/SocializeBlockComponent.vue'
import SlideComponent from '@/views/pages/components/SlideComponent.vue'
import LoginForm from '@/views/pages/login/LoginForm.vue'

import JobCard from '@/views/dashboard/vacancies/components/JobCard.vue'
import ProposalCard from '@/views/pages/components/ProposalCard.vue'

import Commodities from '@/views/components/external/Commodities.vue'
import Exchange from '@/views/components/external/Exchange.vue'

import SomeReleases from '@/views/pages/components/SomeReleases.vue'
import Grin from '@/views/dashboard/company/Grin.vue'

export default {
  components: {
    SocializeBlockComponent,
    SlideComponent,
    LoginForm,
    JobCard,
    ProposalCard,
    Commodities,
    Exchange,
    SomeReleases,
    Grin
  },
  data() {
    return {
      userslist: [],
      vacancieslist: [],
      proposallist: [],
      companylist: [],
      loader: {
        users: true,
        image: true,
        company: true,
        rfp: true,
        vacancies: true,
        professional: true
      }
    }
  },
  methods: {
    toLoggin() {
      this.$refs['logginref'].scrollIntoView({ behavior: "smooth" })
    },
    toBottom() {
      // this.$vuetify.goTo(target, options)
    },
    loadImage() {
      this.loader.image = true;
    },
  },
  beforeMount() {
    this.loader.users = true
    Drequest.api("lazyloading.user?dfilters=on&next=1&per_page=6")
      .get((response) => {
        if (response.success === true) {
          response.listEntity.forEach((value, index) => {
            this.userslist.push({
              id: value.id,
              firstname: value.firstname,
              lastname: value.lastname,
              username: value.username,
              logo: value.logo,
              country: value.country
            })
          })
          this.loader.users = false
        }
      })
      .catch((err) => {
        console.log(err)
        this.loader.users = false;
      })
    Drequest.api(`lazyloading.job?dfilters=on&next=1&per_page=3`)
      .get((response) => {
        if (response.success === true) {
          response.listEntity.forEach((value, index) => {
            this.vacancieslist.push(value)
          });
          this.loader.vacancies = false
        }
        else {
          this.loader.vacancies = false
        }
      })
      .catch((err) => {
        console.log("err")
        this.loader.vacancies = false
      })

    Drequest.api(`lazyloading.rfpenterprise?dfilters=on&status=1&next=1&per_page=3`)
      .get((response) => {
        if (response.success === true) {
          response.listEntity.forEach((element, value) => {
            this.proposallist.push({
              "id": element.id,
              "comment": element.comment,
              "state": element.state,
              "status": element.status,
              "enterprise": element.enterprise,
              "request": element.rfp
            })
            this.loader.rfp = false
          });
        }
      })
      .catch((err) => {
        console.log("err")
        this.loader.rfp = false
      })
    Drequest.api("lazyloading.enterprise?dfilters=on&next=1&per_page=6")
      .get((response) => {
        if (response.success === true) {
          response.listEntity.forEach((value, index) => {
            this.companylist.push(value)
          })
          this.loader.enterprise = true
        }
        else {
          this.loader.enterprise = false
        }
      })
      .catch((err) => {
        console.log("err")
        this.loader.enterprise = false
      })
  },
  setup() {
    const icons = {
      mdiArrowDownCircleOutline,
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiHelpCircleOutline
    }
    return {
      icons
    }
  },
}
</script>

<style lang="scss">
//@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

@import '~@/styles/main-style.scss';
@import '~@/styles/utils-style.scss';

// Hover effect
.rel-loader-img {
  position: relative;
}

.float-rel-loader-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
}
</style>
