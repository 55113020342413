<template>
    <v-form ref="form" class="h-full">
        <!-- <div class="mb-4">
            <v-switch v-model="loginAsCompany" color="error" label="LogIn as a business?" class="ma-0 pa-0"
                hide-details>
                <template v-slot:label>
                    <span class="error--text">LogIn as a business?</span>
                </template>
            </v-switch>
        </div> -->
        <v-text-field v-model="email" hide-details outlined dense label="Email" type="email"
            placeholder="john@example.com" :rules="inputRules" clearable class="mb-3">
        </v-text-field>
        <v-text-field v-model="password" hide-details outlined dense :rules="passRules"
            :type="isPasswordVisible ? 'text' : 'password'" label="Password" placeholder="············"
            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            @click:append="isPasswordVisible = !isPasswordVisible" clearable></v-text-field>

        <div class="d-flex justify-space-between flex-wrap mt-2 py-1">
            <v-checkbox label="Remember Me" hide-details class="my-auto">
            </v-checkbox>
            <span @click="toForgotPassUI" class="btn-forgot-pass my-auto">
                Forgot Password?
            </span>
        </div>

        <div class="d-flex justify-center mt-5">
            <v-btn color="primary" block :loading="this.loading ? true : false" @click="submit">
                Log in
            </v-btn>
        </div>
    </v-form>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
export default {
    data() {
        return {
            loading: false,
            readyT: false,
        }
    },
    methods: {
        toForgotPassUI() {
            this.$router.push({ name: 'forgotpass' });
        },
        onReadyAndSendNewColaboration(ids) {
            // alert(this.$store.getters.getUser.getUserId)
            var formColaborator = {
                "owner": this.$store.getters.getUser.id,
                "recipient": this.$store.getters.getColaboration.recipient,
            }
            Drequest.api("colaboration.send")
                .toFormdata(formColaborator)
                .post((response) => {
                    switch (response.success) {
                        case true:
                            this.$fire({
                                type: response.etablished === true ? "error" : "success",
                                text: response.detail,
                                timer: 3000
                            })
                            this.$store.dispatch('deletecolaboration').then((res) => {
                                window.console.log(res);
                            })
                                .catch(err => {
                                    window.console.log(err)
                                })
                            this.$router.push({ name: 'user.join.list' });
                            break;
                        default:
                            this.$fire({
                                type: "Error",
                                text: response.detail,
                                timer: 3000
                            })
                            this.loading = false
                            break;
                    }
                })
                .catch((err) => {
                    this.$fire({
                        type: "error",
                        text: "Connexion error..!"
                    })
                    this.loading = false;
                });
        },
        clearform() {
            this.password = ''
            this.email = ''
        },
        submit() {
            this.loading = true;

            var bodyFormData = {
                login: this.email,
                password: this.password
            }

            var isSuccess = false
            Drequest.api("user.authentification")
                .toFormdata(bodyFormData)
                .post((response) => {
                    if (response.success == true) {
                        this.loading = false;
                        isSuccess = true
                        this.$store.commit("setUser", {
                            id: response.user.id,
                            email: response.user.email,
                            firstname: response.user.firstname,
                            lastname: response.user.lastname,
                            phonenumber: response.user.phonenumber,
                            username: response.user.username,
                            isactivated: response.user.is_activated,
                            birthdate: response.user.birthdate,
                            adress: response.user.adress,
                            city: response.user.city,
                            lastlogin: response.user.last_login,
                            logo: response.user.logo,
                            // api_key: response.user.api_key
                        });

                        this.$store.commit("setToken", response.user.api_key);
                        this.$store.commit("setApiToken", response.user.activationcode);
                        // localStorage.setItem('kwauths', JSON.stringify(response.user.api_key))

                        // alert(response.user.api_key)

                        this.loading = false;

                        // this.setDataItem(true, "info", 5, response.detail);
                        // !this.$store.getters.isLoggedIn ? this.$router.push('/activation-code') : this.$router.push('/dashboard');
                        if ((this.$store.getters.getColaboration.initialized === true) && (isSuccess === true)) {
                            this.onReadyAndSendNewColaboration(response.user.id)
                            this.$store.commit("setColaboration", {});
                        }

                        else {
                            this.$router.push({ name: 'user.home' });
                        }

                        this.clearform()
                    }
                    else {
                        this.loading = false;
                        this.$fire({
                            type: "error",
                            text: "Incorrect Email or Password..!",
                        })
                    }
                })
                .catch((err) => {
                    this.loading = false;
                })
        },
    },
    setup() {

        let isPasswordVisible = ref(false)
        let email = ref('')
        let password = ref('')

        let icons = {
            mdiEyeOutline,
            mdiEyeOffOutline,
        }
        return {
            isPasswordVisible,
            email,
            password,
            icons,
            inputRules: [
                v => (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v)) || ""
            ],
            passRules: [
                v => v.length > 4 || ""
            ]
        }
    },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.btn-forgot-pass {
    color: var(--v-primary-base);
    cursor: pointer;
}
</style>
